import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import FormControlLabel from '@material-ui/core/FormControlLabel';
export const primaryColor = '#cc4a4a';
export const greenColor = '#4acc4a';
export const _frontmatter = {
  "title": "IndexPage"
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Intro = makeShortcode("Intro");
const Icon = makeShortcode("Icon");
const Button = makeShortcode("Button");
const ContentContainer = makeShortcode("ContentContainer");
const BlockHead = makeShortcode("BlockHead");
const Grid = makeShortcode("Grid");
const Typography = makeShortcode("Typography");
const Box = makeShortcode("Box");
const Col = makeShortcode("Col");
const Row = makeShortcode("Row");
const Avatar = makeShortcode("Avatar");
const Image = makeShortcode("Image");
const Form = makeShortcode("Form");
const TextInputField = makeShortcode("TextInputField");
const ScrollToTop = makeShortcode("ScrollToTop");
const Footer = makeShortcode("Footer");
const layoutProps = {
  primaryColor,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <Intro backgroundSource={'./Intro00.jpg'} text={['', 'Mobiler Kranken Fahrservice', 'Ihr zuverlässiger Partner bei bundesweiten Krankenfahrten, Liegend- und Tragestuhltransporten', 'Jetzt auch bei Transporten von Corona/Covid-19 Fällen', <>
      Rufen Sie uns an:{' '}
      <span style={{
        color: primaryColor,
        fontWeight: '750'
      }}>
        <a href='tel:+08008837726'>0800 883 77 26</a>
      </span>
    </>, '', '', <div style={{
      display: 'flex',
      flexDirectiom: 'row',
      alignItems: 'center'
    }}>
      <Icon style={{
        color: greenColor,
        marginRight: '16px'
      }} mdxType="Icon" />
      Transporte 7 Tage von 06.00 - 22.00 Uhr
    </div>, <div style={{
      display: 'flex',
      flexDirectiom: 'row',
      alignItems: 'center'
    }}>
      <Icon style={{
        color: greenColor,
        marginRight: '16px'
      }} mdxType="Icon" />
      Bundesweite Transporte
    </div>, <div style={{
      display: 'flex',
      flexDirectiom: 'row',
      alignItems: 'center'
    }}>
      <Icon style={{
        color: greenColor,
        marginRight: '16px'
      }} mdxType="Icon" />
      Partner aller Krankenkassen
    </div>, <div style={{
      display: 'flex',
      flexDirectiom: 'row',
      alignItems: 'center'
    }}>
      <Icon style={{
        color: greenColor,
        marginRight: '16px'
      }} mdxType="Icon" />
      Speziell ausgebildetes Personal
    </div>]} mdxType="Intro">
  <Button component={'a'} href={'/#kontakt'} size={'large'} style={{
        padding: '16px 32px',
        color: '#fff',
        backgroundColor: '#cc4a4a',
        fontWeight: 'bold',
        fontSize: '26px',
        textAlign: 'center'
      }} mdxType="Button">
    Transport anfordern
  </Button>
    </Intro>
    <ContentContainer id={'services'} mdxType="ContentContainer">
  <BlockHead size={'xl'} title={['', 'Unsere Leistungen', <span>Kontaktieren Sie uns gerne bei Fragen</span>]} mdxType="BlockHead" />
  <Grid container spacing={2} mdxType="Grid">
    <Grid item xs={12} md={4} mdxType="Grid">
      <BlockHead size={'lg'} title={['', 'Allgemeine Leistungen', '', '', '', 'Einweisungsfahrten', 'Entlassungsfahrten', 'Verlegungsfahrten', 'Dialysefahrten', 'Serienfahrten', 'Chemotherapiefahrten', 'Strahlentherapiefahrten', 'Tagesklinikfahrten']} mdxType="BlockHead" />
    </Grid>
    <Grid item xs={12} md={4} mdxType="Grid">
      <BlockHead size={'lg'} title={['', 'Private Leistungen', '', '', '', 'Einweisungsfahrten', 'Entlassungsfahrten', 'Verlegungsfahrten', 'Dialysefahrten', 'Serienfahrten', 'Chemotherapiefahrten', 'Strahlentherapiefahrten', 'Tagesklinikfahrten']} mdxType="BlockHead" />
    </Grid>
    <Grid item xs={12} md={4} mdxType="Grid">
      <BlockHead size={'lg'} title={['', 'Spezielle Leistungen', '', '', '', 'Konsilfahrten für Kliniken', 'Interne Verlegungen für Kliniken', 'Materialfahrten für Kliniken', 'Laborfahrten für Kliniken', <div style={{
            width: '100%'
          }}>
            <div style={{
              margin: '0 18%',
              backgroundColor: primaryColor,
              color: '#fff',
              padding: '4px'
            }}>
              <strong>Covid-19/SARS Transporte</strong>
            </div>
          </div>]} mdxType="BlockHead" />
    </Grid>
  </Grid>
    </ContentContainer>
    <ContentContainer is={'spacer'} isTransparent style={{
      minHeight: '348px',
      padding: '48px 0'
    }} mdxType="ContentContainer">
  <BlockHead mode={'dark'} size={'xl'} title={['Durchgehend im Einsatz', <em>0800 883 77 26</em>, <span>Kontaktieren Sie uns zu jeder Zeit!</span>]} mdxType="BlockHead" />
    </ContentContainer>
    <ContentContainer id={'transports'} mdxType="ContentContainer">
  <BlockHead size={'xl'} title={['Unsere', 'Transporte', <span>Bei besonderen Anforderungen</span>]} mdxType="BlockHead" />
  <Grid container spacing={3} mdxType="Grid">
    <Grid item sm={4} mdxType="Grid">
      <BlockHead style={{
            padding: '0 16px'
          }} size={'lg'} title={['', <Typography paragraph variant={'h4'} renderAs={'p'} mdxType="Typography">
            Liegend Transport
          </Typography>, '', '', '', <div style={{
            minHeight: '220px'
          }}>
            <Typography paragraph variant={'body1'} mdxType="Typography">
              {'Der Liegend-Transport der Mobiler Kranken Fahrservice hält Sie mobil, wenn Sie durch Krankheit, Verletzung oder Behinderung so eingeschränkt sind, daß Sie während eines Transportes liegen müssen. Mit Hilfe unseres Services ist das Überwinden von Treppen oder Stufen auch im Liegen kein Problem für Sie. Die technische Einrichtung unserer Fahrzeuge ist stets geprüft und auf dem neuesten Stand.'}
            </Typography>
          </div>, '', <Typography paragraph variant={'body1'} mdxType="Typography">
            {'Unsere Mitarbeiter die geschult sind, tragen Sie in speziellen Trage Stühlen oder Tragen aus Ihrer Wohnung bis zum Fahrzeug, wenn notwendig verwenden wir auch ein spezielles Tragetuch und bringen Sie sicher und komfortabel in die Arztpraxis, das Pflegeheim oder das Krankenhaus.'}
          </Typography>]} mdxType="BlockHead" />
    </Grid>
    <Grid item sm={4} mdxType="Grid">
      <BlockHead style={{
            padding: '0 16px'
          }} size={'lg'} title={['', <Typography paragraph variant={'h4'} renderAs={'p'} mdxType="Typography">
            Tragestuhl Transport
          </Typography>, '', '', '', <div style={{
            minHeight: '220px'
          }}>
            <Typography paragraph variant={'body1'} mdxType="Typography">
              {'Für Menschen, die nicht mehr gehfähig sind, aber sitzend transportiert werden können, bietet Ihnen der Mobiler Kranken Fahrservice einen komfortablen Krankentransport im Tragestuhl an. Das Überwinden von Treppen und Stufen stellt für Sie somit kein Problem mehr dar.'}
            </Typography>
          </div>, '', <Typography paragraph variant={'body1'} mdxType="Typography">
            {'Unsere geschultes Personal führen einen Tragestuhl Transport immer zu zweit durch. Dabei werden die Patienten zu Hause abgeholt, zunächst ins Fahrzeug und am Zielort bis in den Behandlungsraum des Arztes, Pflegeheim oder des Krankenhauses getragen. Die Mitarbeiter der MKF benutzen dazu spezielle TÜV-geprüfte Tragestühle, die ein Höchstmaß an Sicherheit und Komfort bieten.'}
          </Typography>]} mdxType="BlockHead" />
    </Grid>
    <Grid item sm={4} mdxType="Grid">
      <BlockHead style={{
            padding: '0 16px'
          }} size={'lg'} title={['', <Typography paragraph variant={'h4'} renderAs={'p'} mdxType="Typography">
            Rollstuhl Transport
          </Typography>, '', '', '', <div style={{
            minHeight: '220px'
          }}>
            <Typography paragraph variant={'body1'} mdxType="Typography">
              {'Mit dem besonderen Service des Rollstuhltransport bieten wir Ihnen die Möglichkeit während des Transportes bequem in Ihrem eigenen Rollstuhl sitzen zu bleiben. Hierfür haben wir komfortabel eingerichtete Spezialfahrzeuge mit TÜV geprüften Auffahrrampen und sicheren Befestigungsvorrichtungen für Ihren Rollstuhl.'}
            </Typography>
          </div>, '', <Typography paragraph variant={'body1'} mdxType="Typography">
            {'Unser professionell geschultes Personal sorgt dafür, dass Ihre Fahrt in unseren Fahrzeugen so angenehm wie möglich wird.'}
          </Typography>, <Typography paragraph variant={'body1'} mdxType="Typography">
            <em>
              {'Natürlich wird Ihnen auch ein Rollstuhl von uns gestellt falls Sie einen benötigen.'}
            </em>
          </Typography>]} mdxType="BlockHead" />
    </Grid>
  </Grid>
    </ContentContainer>
    <ContentContainer id={'about'} isTransparent style={{
      minHeight: '348px',
      padding: '48px 0'
    }} mdxType="ContentContainer">
  <Box mdxType="Box">
    <Grid container spacing={4} mdxType="Grid">
      <Grid item xs={12} md={8} mdxType="Grid">
        <BlockHead mode={'dark'} size={'lg'} textAlign={'left'} title={['Wir sind MKF', 'Unsere Fähigkeiten sind sehr vielfältig', <Typography style={{
              fontWeight: 'bolder'
            }} mdxType="Typography">
              {'Seit 1990 für Sie zuverlässig unterwegs'}
            </Typography>]} mdxType="BlockHead">
          <div>
            <Typography style={{
                  padding: '16px 0',
                  fontWeight: 'bolder'
                }} mdxType="Typography">
              {'Wir sind ein junges und expandierendes Unternehmen im Bereich des Krankenfahrdienstes. Höchste Qualität, Sicherheit und Ihre Zufriedenheit stehen für uns an erster Stelle. Wenn die Beweglichkeit durch Krankheit, Behinderung oder Verletzung eingeschränkt ist, hält Sie die Firma MKF GmbH mobil. Speziell geschultes Personal und komfortabel eingerichtete Krankentransportfahrzeuge (ohne medizinisches Gerät) sind die Qualitätsmerkmale für unseres Hauses und sorgen für eine angenehme und sichere Fahrt.'}
            </Typography>
          </div>
        </BlockHead>
      </Grid>
      <Grid item xs={12} md={4} mdxType="Grid">
        <Col mdxType="Col">
          <Row mdxType="Row">
            <div style={{
                  width: '100%',
                  color: '#fff',
                  fontWeight: '600'
                }}>
              <div style={{
                    padding: '2px 8px'
                  }}>Einsatz</div>
              <div style={{
                    backgroundColor: '#222',
                    width: '100%',
                    borderRadius: '2px'
                  }}>
                <div style={{
                      backgroundColor: '#cc4a4a',
                      width: '100%',
                      borderBottomLeftRadius: '4px',
                      borderTopLeftRadius: '4px'
                    }}>
                  <Typography style={{
                        padding: '2px 8px',
                        fontWeight: 'bolder'
                      }} mdxType="Typography">100%</Typography>
                </div>
              </div>
            </div>
          </Row>
          <Row mdxType="Row">
            <div style={{
                  width: '100%',
                  color: '#fff',
                  fontWeight: '600'
                }}>
              <div style={{
                    padding: '2px 8px'
                  }}>Zuverlässigkeit</div>
              <div style={{
                    backgroundColor: '#222',
                    width: '100%',
                    borderRadius: '2px'
                  }}>
                <div style={{
                      backgroundColor: '#cc4a4a',
                      width: '99%',
                      borderBottomLeftRadius: '4px',
                      borderTopLeftRadius: '4px'
                    }}>
                  <Typography style={{
                        padding: '2px 8px',
                        fontWeight: 'bolder'
                      }} mdxType="Typography">99%</Typography>
                </div>
              </div>
            </div>
          </Row>
          <Row mdxType="Row">
            <div style={{
                  width: '100%',
                  color: '#fff',
                  fontWeight: '600'
                }}>
              <div style={{
                    padding: '2px 8px'
                  }}>Zufriedenheit</div>
              <div style={{
                    backgroundColor: '#222',
                    width: '100%',
                    borderRadius: '2px'
                  }}>
                <div style={{
                      backgroundColor: '#cc4a4a',
                      width: '97.8%',
                      borderBottomLeftRadius: '4px',
                      borderTopLeftRadius: '4px'
                    }}>
                  <Typography style={{
                        padding: '2px 8px',
                        fontWeight: 'bolder'
                      }} mdxType="Typography">
                    97,8%
                  </Typography>
                </div>
              </div>
            </div>
          </Row>
          <Row mdxType="Row">
            <div style={{
                  width: '100%',
                  color: '#fff',
                  fontWeight: '600'
                }}>
              <div style={{
                    padding: '2px 8px'
                  }}>Ausfälle</div>
              <div style={{
                    backgroundColor: '#222',
                    width: '100%',
                    borderRadius: '2px'
                  }}>
                <div style={{
                      backgroundColor: '#cc4a4a',
                      width: '0%',
                      borderBottomLeftRadius: '4px',
                      borderTopLeftRadius: '4px'
                    }}>
                  <div style={{
                        padding: '2px 8px',
                        fontWeight: 'bolder',
                        minWidth: '100%',
                        position: 'relative'
                      }}>
                    0%
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Col>
      </Grid>
    </Grid>
  </Box>
    </ContentContainer>
    <ContentContainer isHidden id={'stats'} mdxType="ContentContainer">
  <Box mdxType="Box">
    <Grid container spacing={10} mdxType="Grid">
      <Grid item sm={3} xs={12} mdxType="Grid">
        <BlockHead size={'xl'} textAlign={'center'} title={['', '10000', '100% Zufriedene Kunden']} mdxType="BlockHead" />
      </Grid>
      <Grid item sm={3} xs={12} mdxType="Grid">
        <BlockHead size={'xl'} textAlign={'center'} title={['', '1000', 'Krankenhäuser']} mdxType="BlockHead" />
      </Grid>
      <Grid item sm={3} xs={12} mdxType="Grid">
        <BlockHead size={'xl'} textAlign={'center'} title={['', '100', 'Prozent Einsatz']} mdxType="BlockHead" />
      </Grid>
      <Grid item sm={3} xs={12} mdxType="Grid">
        <BlockHead size={'xl'} textAlign={'center'} title={['', '1', 'Aufgabe']} mdxType="BlockHead" />
      </Grid>
      <Grid item xs={12} mdxType="Grid">
        <BlockHead size={'xl'} textAlign={'center'} title={['', 'Ihre Zufriedenheit!']} mdxType="BlockHead" />
      </Grid>
    </Grid>
  </Box>
  <Box style={{
        textAlign: 'center'
      }} mdxType="Box">
    <h1 style={{
          color: '#fff',
          fontSize: '3em'
        }}>"Die Schnellsten im Business!"</h1>
  </Box>
  <Box style={{
        textAlign: 'right',
        paddingRight: '3em',
        marginTop: '-2em'
      }} mdxType="Box">
    <h1 style={{
          color: '#fff'
        }}>Laut meiner Oma ❤️</h1>
  </Box>
    </ContentContainer>
    <ContentContainer id={'covid'} mdxType="ContentContainer">
  <Box mdxType="Box">
    <BlockHead size={'xl'} textAlign={'center'} title={['Aktuelles', 'SARS / COVID 19 ', 'was es zu beachten gibt']} mdxType="BlockHead">
      <div style={{
            margin: '32px 0'
          }}>
        <Typography variant={'h5'} renderAs={'p'} mdxType="Typography">
          COVID – 19 Entlassungs- und Behandlungsfahrten Rollstuhl-, Liegend- und Tragestuhlfahrten
        </Typography>
      </div>
      <div style={{
            margin: '32px 0'
          }}>
        <Typography mdxType="Typography">
          Unser Unternehmen befördert COVID 19 Patienten, nach Ordnungsgemäßer Reinigung der
          Fahrzeuge und korrektes Anlegen der Schutzkleidung nach den internen Hygienerichtlinien.
          Diese Zusatzleistungen werden, <em>ohne jegliche Zusatzkosten</em> durchgeführt.
        </Typography>
      </div>
    </BlockHead>
  </Box>
    </ContentContainer>
    <ContentContainer id={'team'} isTransparent mdxType="ContentContainer">
  <Box mdxType="Box">
    <Row mdxType="Row">
      <BlockHead mode={'dark'} size={'xl'} textAlign={'center'} title={['Unsere', 'Disposition', 'Zugeschnitten auf die Wünsche unserer Kunden', 'freundlich und kompetent']} mdxType="BlockHead" />
    </Row>
    <Grid container spacing={10} mdxType="Grid">
      <Grid item sm={4} xs={12} mdxType="Grid">
        <Avatar name={'Daniel Schwarz'} mode={'dark'} rounded={true} mdxType="Avatar">
          {['Disponent']}
        </Avatar>
      </Grid>
      <Grid item sm={4} xs={12} mdxType="Grid">
        <Avatar name={'Susanne Schuster'} mode={'dark'} rounded mdxType="Avatar">
          {['Disponent']}
        </Avatar>
      </Grid>
      <Grid item sm={4} xs={12} mdxType="Grid">
        <Avatar name={'Marijo Mandic'} mode={'dark'} rounded mdxType="Avatar">
          {['Disponent']}
        </Avatar>
      </Grid>
    </Grid>
  </Box>
    </ContentContainer>
    <ContentContainer id={'vehicles'} mdxType="ContentContainer">
  <Box style={{
        padding: '64px 0'
      }} mdxType="Box">
    <Row style={{
          padding: '16px 0'
        }} mdxType="Row">
      <BlockHead mode={'light'} size={'xl'} textAlign={'center'} title={['Der modernste', 'Fuhrpark', 'in Frankfurt und Umgebung']} mdxType="BlockHead" />
    </Row>
    <Grid container spacing={2} mdxType="Grid">
      {
            /* <Grid item xs={12}>
             <Image src={'./pic00.webp'} />
            </Grid> */
          }
      <Grid item xs={12} md={6} mdxType="Grid">
        <Image src={'./00.webp'} style={{
              borderRadius: '4px'
            }} mdxType="Image" />
      </Grid>
      <Grid item xs={12} md={6} mdxType="Grid">
        <Image src={'./01.webp'} style={{
              borderRadius: '4px'
            }} mdxType="Image" />
      </Grid>
      <Grid item xs={12} md={6} mdxType="Grid">
        <Image src={'./02.webp'} style={{
              borderRadius: '4px'
            }} mdxType="Image" />
      </Grid>
      <Grid item xs={12} md={6} mdxType="Grid">
        <Image src={'./03.webp'} style={{
              borderRadius: '4px'
            }} mdxType="Image" />
      </Grid>
    </Grid>
  </Box>
    </ContentContainer>
    <ContentContainer id={'kontakt'} isTransparent mdxType="ContentContainer">
  <Row mdxType="Row">
    <BlockHead mode={'dark'} size={'xl'} textAlign={'center'} title={['Rufen Sie uns zu jeder Zeit an', <div style={{
          backgroundColor: '#222',
          lineHeight: '48px',
          borderRadius: '24px',
          margin: '16px 0',
          padding: '4px'
        }}>
          <a href='tel:+08008837726'>0800 883 77 26</a>
        </div>, 'Setzen Sie sich mit uns direkt in Verbindung', 'Wir freuen uns auf Ihren Anruf!']} mdxType="BlockHead" />
  </Row>
  <Row mdxType="Row">
    <Button component={'a'} color={'primary'} href={`mailto:info@mkf-service.com?subject=%3CAnfrage%20via%20Website-Email%20Kontaktformular%3E%20Betreff:%20&body=${`
          --- Sie können Ihre Telefonnummer mitsenden, wir rufen Sie gerne zurück! ---
          `}`} size={'large'} style={{
          padding: '16px 32px',
          marginBottom: '96px',
          color: '#fff',
          fontWeight: 'bold',
          fontSize: '26px',
          textAlign: 'center'
        }} mdxType="Button">
      EMAIL SENDEN
    </Button>
  </Row>
  <Form title={'Transport anfordern'} config={[{
        name: 'Kontaktdaten:',
        fields: [{
          id: 'from_name',
          label: 'Name und Vorname*',
          as: <TextInputField mdxType="TextInputField" />,
          color: 'secondary',
          variant: 'filled',
          rules: {
            required: true
          },
          type: 'string'
        }, {
          id: 'patient_name',
          label: 'Vorname und Name des Patienten*',
          as: <TextInputField mdxType="TextInputField" />,
          color: 'secondary',
          variant: 'filled',
          rules: {
            required: true
          },
          type: 'string'
        }, {
          id: 'phone',
          label: 'Telefonnummer*',
          as: <TextInputField mdxType="TextInputField" />,
          color: 'secondary',
          variant: 'filled',
          rules: {
            required: true
          },
          type: 'string'
        }, {
          id: 'from_email',
          label: 'Email*',
          as: <TextInputField mdxType="TextInputField" />,
          color: 'secondary',
          variant: 'filled',
          rules: {
            required: true
          },
          type: 'string'
        }]
      }, {
        name: 'Transport von:',
        fields: [{
          id: 'from_address_street',
          label: 'Straße und Hausnummer*',
          as: <TextInputField mdxType="TextInputField" />,
          color: 'secondary',
          variant: 'filled',
          rules: {
            required: true
          },
          type: 'string'
        }, {
          id: 'from_address_location',
          label: 'Postleitzahl und Ort*',
          as: <TextInputField mdxType="TextInputField" />,
          color: 'secondary',
          variant: 'filled',
          rules: {
            required: true
          },
          type: 'string'
        }]
      }, {
        name: 'Transport nach:',
        fields: [{
          defaultValue: '',
          id: 'target_address_street',
          label: 'Straße und Hausnummer*',
          as: <TextInputField mdxType="TextInputField" />,
          color: 'secondary',
          variant: 'filled',
          rules: {
            required: true
          },
          type: 'string'
        }, {
          defaultValue: '',
          id: 'target_address_location',
          label: 'Postleitzahl und Ort*',
          as: <TextInputField mdxType="TextInputField" />,
          color: 'secondary',
          variant: 'filled',
          rules: {
            required: true
          },
          type: 'string'
        }]
      }, {
        name: 'Termindaten:',
        fields: [{
          defaultValue: '',
          id: 'at_date',
          label: 'Datum der Abholung* (DD:MM:YYYY)',
          as: <TextInputField mdxType="TextInputField" />,
          color: 'secondary',
          variant: 'filled',
          rules: {
            required: true
          },
          type: 'string'
        }, {
          defaultValue: '',
          id: 'at_time_pickup',
          label: 'Uhrzeit Abholung* (HH:mm)',
          as: <TextInputField mdxType="TextInputField" />,
          color: 'secondary',
          variant: 'filled',
          rules: {
            required: true
          },
          type: 'string'
        }, {
          defaultValue: '',
          id: 'at_time_target',
          label: 'Uhrzeit Termin* (HH:mm)',
          as: <TextInputField mdxType="TextInputField" />,
          color: 'secondary',
          variant: 'filled',
          rules: {
            required: true
          },
          type: 'string'
        }]
      }, {
        name: 'Angaben zum Transport:',
        fields: [{
          defaultValue: '',
          id: 'transport_type',
          label: 'Transportart*',
          as: <TextInputField mdxType="TextInputField" />,
          color: 'primary',
          variant: 'filled',
          rules: {
            required: true
          },
          type: 'radioGroup',
          options: [{
            id: 'lying',
            value: 'lying',
            label: 'Transport: Liegend'
          }, {
            id: 'carried',
            value: 'carried',
            label: 'Transport: Tragestuhl'
          }, {
            id: 'rolling',
            value: 'rolling',
            label: 'Transport: Rollstuhl'
          }]
        }, {
          defaultValue: '',
          id: 'transport_document',
          label: 'Transportschein vorhanden?*',
          as: <TextInputField mdxType="TextInputField" />,
          color: 'primary',
          variant: 'filled',
          rules: {
            required: true
          },
          type: 'radioGroup',
          options: [{
            id: 'yes',
            value: 'yes',
            label: 'Ja - Vorhanden'
          }, {
            id: 'no',
            value: 'no',
            label: 'Nein - Nicht vorhanden'
          }, {
            id: 'private',
            value: 'private',
            label: 'Private Abrechnung'
          }]
        }]
      }, {
        name: 'Zusätzliche Informationen:',
        fields: [{
          defaultValue: '',
          id: 'message',
          label: 'Ihre Nachricht',
          as: <TextInputField mdxType="TextInputField" />,
          color: 'secondary',
          variant: 'filled',
          type: 'string',
          multiline: true
        }]
      }]} mdxType="Form" />
    </ContentContainer>
    <ScrollToTop mdxType="ScrollToTop" />
    <Footer mdxType="Footer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      
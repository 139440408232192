import firebase from 'firebase/app';
// import 'firebase/functions';
// import 'firebase/analytics'; => gatsby.xxx.js now
// import 'firebase/auth';
// import 'firebase/firestore';
// import 'firebase/functions';
// import 'firebase/storage';
// import 'firebase/remote-config';
import { FirebaseEnvConfig } from './firebase.types';

export const getOrInitFirebaseInstance = (
  firebaseConfig: FirebaseEnvConfig,
  emulate: boolean = false,
) => {
  let app: firebase.app.App | null = null;
  if (typeof window !== 'undefined' && firebase && !firebase?.apps?.length) {
    app = firebase.initializeApp(firebaseConfig);
    // app.functions('europe-west3');
    // console.log('initialized fb with projectId: ', firebaseConfig.projectId); // "[DEFAULT]"

    // const storage = firebase.storage();
    // TODO: create pre-app fb emulator service selection
    if (window.location.hostname === 'localhost' && emulate) {
      // todo control single emulator instances start via env config
      // firebase.auth().useEmulator('http://localhost:9099/');
      // firebase.firestore().useEmulator('localhost', 8080);
      // firebase.functions().useEmulator('localhost', 5001);
      // firebase.firestore.setLogLevel('debug');
      // const func = app.functions('europe-west3');
      // storage.useEmulator()
      console.log('<<<USING FB EMULATORS>>>');
    }
  }
  return app;
};

import * as React from 'react';
import { PageProps } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
// import Img from 'gatsby-image';
import { getOrInitFirebaseInstance } from 'src/lib/firebase';

import IndexMDX from 'mdx/indexpage.mdx';

import { getMDXComponentsConfig } from 'src/config';

import { SeoObject, Site } from '../components/Site';

const seo: SeoObject = {
  title: 'Home',
};

const fbConfig = {
  apiKey: 'AIzaSyAacrMe3xcyyPhOP5p4-mhW8p-7_ghKdnU',
  authDomain: 'mobiler-kranken-fahrservice.firebaseapp.com',
  databaseURL: 'https://mobiler-kranken-fahrservice.firebaseio.com',
  projectId: 'mobiler-kranken-fahrservice',
  storageBucket: 'mobiler-kranken-fahrservice.appspot.com',
  messagingSenderId: '411276567180',
  appId: '1:411276567180:web:65743eb5eda988ed9111a9',
  measurementId: 'G-GJY88BNGKL',
};
// if (typeof window !== 'undefined') {
//   getOrInitFirebaseInstance(fbConfig);
// }

const IndexPage: React.FC<PageProps> = (props: PageProps & { data: any }) => {
  // const data = useStaticQuery(graphql`
  //   query {
  //     file(relativePath: { eq: "SmallLogo.jpg" }) {
  //       childImageSharp {
  //         fluid(maxWidth: 1080, quality: 100) {
  //           base64
  //           aspectRatio
  //           src
  //           srcSet
  //           sizes
  //         }
  //         fixed(width: 250, height: 250) {
  //           base64
  //           aspectRatio
  //           src
  //           srcSet
  //         }
  //       }
  //     }
  //   }
  // `);
  // console.log('Props: ', props);
  // console.log('data: ', data);
  /** initialize firebase */
  getOrInitFirebaseInstance(fbConfig);
  return (
    <Site seo={seo} {...props}>
      <MDXProvider components={getMDXComponentsConfig(props)}>
        <IndexMDX {...props} />
      </MDXProvider>
    </Site>
  );
};

/* <Img fixed={data.file.childImageSharp.fixed} alt="A corgi smiling happily" /> */

/* <Img fluid={data.file.childImageSharp.fluid} alt="A corgi smiling happily" /> */

// export const query = graphql`
//   query {
//     file(relativePath: { eq: "../assets/ok.png" }) {
//       childImageSharp {
//         # Specify the image processing specifications right in the query.
//         # Makes it trivial to update as your page's design changes.
//         fixed(width: 125, height: 125) {
//           ...GatsbyImageSharpFixed
//         }
//       }
//     }
//   }
// `

export default IndexPage;
